.top-pad {
  top: var(--header-height);
  left: 0px;
}
.main-height {
  margin-bottom: var(--footer-height);
}
.payment-success {
  margin-top: var(--black-height);
  margin-bottom: var(--footer-height);
}
.pay {
  margin-bottom: var(--footer-height);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
